
	import { emitter } from '@/emitter';
	import { defineComponent } from 'vue';
	import type { item_obj } from '@/Interfaces';
	import {
		isIOS,
		isAndroid,
		isMobile,
	} from 'mobile-device-detect';
	import * as TranslationHelper from '@/TranslationHelper/TranslationHelper';

	export default defineComponent({
		name: 'LockerModal',
		props: {
			item_prop: Object,
			onCloseClick: Function,
			aff_sub4: String,
			aff_sub5: String,
			geo: String,
			domain: String,
			invalid_conn: Boolean,
		},
		data() {
			return {
				translation: {} as TranslationHelper.Translation,
				item:this.item_prop as item_obj,
				os:'',
			}
		},

		beforeMount() {
			// TranslationHelper.loadAppropriateTranslation();
			this.translation = TranslationHelper.getTranslation();
		},
		mounted() {
			// if(typeof this.item.title !== 'undefined' && this.item.title.length > 28) {
			// 	let locker_modal_title = this.$refs.locker_modal_title as HTMLDivElement;
			// 	locker_modal_title.classList.add('locker_modal_title_smaller');
			// }

			this.setUserOS();

			(async () => {
				await this.sleep(0.5);

				const locker_modal_last_line_top = this.$refs.locker_modal_last_line_top as HTMLDivElement;
				locker_modal_last_line_top.addEventListener('animationend', this.wait_and_restart_locker_modal_last_line_width_anim_top, false);
				locker_modal_last_line_top.classList.add('locker_modal_last_line_anim');

				const locker_modal_last_line_bottom = this.$refs.locker_modal_last_line_bottom as HTMLDivElement;
				locker_modal_last_line_bottom.addEventListener('animationend', this.wait_and_restart_locker_modal_last_line_width_anim_bottom, false);
				locker_modal_last_line_bottom.classList.add('locker_modal_last_line_anim');
			})();
		},


		methods: {
			onCloseClick_func() {
				if(typeof this.onCloseClick === 'function') {
					emitter.emit(`resume-all-background-animations`);
					this.onCloseClick();
				}
			},

			// openInNewTab(href:string) {
			// 	if(!this.invalid_conn) {
			// 		// console.log(`LockerModal: openInNewTab(): href: ${href}`);
			// 		let click_href = `${this.domain}/cl/click.php?u=${btoa(href)}&geo=${this.geo}&r=${this.aff_sub4}&oid=${this.item.key}&ot=${btoa(this.item.title)}`;
			// 		Object.assign(document.createElement('a'), {
			// 			target: '_blank',
			// 			rel: 'noopener noreferrer',
			// 			href: click_href,
			// 		}).click();
			// 	} else {
			// 		alert(`ERROR:\nPlease, use your real IP address, then reload the page.`);
			// 	}
			// },

			setUserOS() {
				if(isIOS) { this.os = 'ios'; }
				else if(isAndroid) { this.os = 'android'; }
				else if(!isMobile) { this.os = 'desktop'; }
			},

			openInNewTab(href:string) {

				let invalid_conn = this.invalid_conn;
				let geo = this.geo;

				// if(invalid_conn && String(geo).toUpperCase() === "US" && (this.os === "android" || this.os === "desktop")) {
				if(invalid_conn && (this.os === "android" || this.os === "desktop")) {
					// alert(`ERROR:\nPlease, use your real IP address, then reload the page.`);
					alert(this.translation.LockerModal.openInNewTab_ip_error);
				} else {
					// console.log(`LockerModal: openInNewTab(): href: ${href}`);
					let click_href = `${this.domain}/cl/click.php?u=${btoa(href)}&geo=${geo}&r=${this.aff_sub4}&oid=${this.item.key}&ot=${btoa(this.item.title)}`;
					Object.assign(document.createElement('a'), {
						target: '_blank',
						rel: 'noopener noreferrer',
						href: click_href,
					}).click();
				}
			},

			async wait_and_restart_shake_anim() {
				const shake_title = this.$refs.shake_title as HTMLDivElement;
				shake_title.removeEventListener('animationend', this.wait_and_restart_shake_anim);
				shake_title.classList.remove('shake_anim');
				await this.sleep(3);
				shake_title.addEventListener('animationend', this.wait_and_restart_shake_anim, false);
				shake_title.classList.add('shake_anim');
			},
			
			async wait_and_restart_locker_modal_last_line_width_anim_top() {
					const locker_modal_last_line = this.$refs.locker_modal_last_line_top as HTMLDivElement;
					locker_modal_last_line.removeEventListener('animationend', this.wait_and_restart_locker_modal_last_line_width_anim_top);
					locker_modal_last_line.classList.remove('locker_modal_last_line_anim');
					await this.sleep(2.86);
					locker_modal_last_line.addEventListener('animationend', this.wait_and_restart_locker_modal_last_line_width_anim_top, false);
					locker_modal_last_line.classList.add('locker_modal_last_line_anim');
			},

			async wait_and_restart_locker_modal_last_line_width_anim_bottom() {
					const locker_modal_last_line = this.$refs.locker_modal_last_line_bottom as HTMLDivElement;
					locker_modal_last_line.removeEventListener('animationend', this.wait_and_restart_locker_modal_last_line_width_anim_bottom);
					locker_modal_last_line.classList.remove('locker_modal_last_line_anim');
					await this.sleep(2.86);
					locker_modal_last_line.addEventListener('animationend', this.wait_and_restart_locker_modal_last_line_width_anim_bottom, false);
					locker_modal_last_line.classList.add('locker_modal_last_line_anim');
			},

			sleep(seconds:number) {
				return new Promise((resolve, reject) => {
					setTimeout(() => { resolve(null); }, seconds * 1000);
				});
			}
		}
	})
