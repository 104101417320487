import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Content" }
const _hoisted_2 = {
  className: "copiedNotificationContainer",
  ref: "copiedNotification"
}
const _hoisted_3 = {
  className: "copiedNotification",
  ref: "copiedNotification"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LockerModal = _resolveComponent("LockerModal")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.locker_modal_visible)
      ? (_openBlock(), _createBlock(_component_LockerModal, {
          key: 0,
          invalid_conn: _ctx.invalid_conn,
          domain: _ctx.domain,
          aff_sub4: _ctx.aff_sub4,
          aff_sub5: _ctx.aff_sub5,
          geo: _ctx.geo,
          item_prop: _ctx.selected_item,
          onCloseClick: () => { _ctx.closeLockerModal(); }
        }, null, 8, ["invalid_conn", "domain", "aff_sub4", "aff_sub5", "geo", "item_prop", "onCloseClick"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, " Copied successfully! ", 512)
    ], 512),
    _createVNode(_component_router_view)
  ]))
}