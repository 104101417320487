export function logt(tag:string, msg_1:string, msg_2?:any): void {
	if(typeof msg_2 !== "undefined") {
		console.log(`[${logTime()}] ${tag}: ${msg_1}`, msg_2);
	} else {
		console.log(`[${logTime()}] ${tag}: ${msg_1}`);
	}
}

export function logTime(): string {
	return `${convertDateTime(String(Date.now()))}`;
}

export function convertDateTime(timestamp:string) {
	let timestamp_1 = Number(timestamp);
	const nth = function(d:number) {
		if (d > 3 && d < 21) return 'th';
		switch (d % 10) {
			case 1:  return "st";
			case 2:  return "nd";
			case 3:  return "rd";
			default: return "th";
		}
	}
	
	const fortnightAway = new Date(timestamp_1);
	const date = fortnightAway.getDate();
	let time = fortnightAway.toLocaleString('en-US', { 
		hour: 'numeric', 
		minute: 'numeric', 
		second:'numeric', 
		hour12: false 
	});
	const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][fortnightAway.getMonth()];
	let str = `${String(Number(fortnightAway.getMonth())+1)}/${date}/${String(fortnightAway.getFullYear()).slice(2,4)} ${time}`;
	return str;
}

export function sleep(seconds:number) {
	return new Promise((resolve, reject) => {
		setTimeout(() => {resolve(null); },seconds * 1000);
	});
}
