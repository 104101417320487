import axios from 'axios';

export interface Translation {
  Locker: {
    LockerItemModal: {
      locker_item_modal_title: string;
      locker_item_modal_buttons_container: string;
      openInNewTab_ip_error: string;
    };
    LockerListItem: {
      locker_list_item_button: string;
    };
    LockerListModal: {
      locker_list_modal_header_title: string;
      locker_list_modal_header_subtitle: string;
    };
  };
  SeoSv: {
    SeoSvModal: {
      seosv_modal_panel_content_h1: string;
      seosv_modal_panel_content_ul_li_label: {
        cancel: string;
        youtube: string;
        tiktok: string;
        google: string;
        bing: string;
        others: string;
      };
    };
  };
  LockerListItem: {
    locker_list_item_button: string;
  };
  LockerModal: {
    locker_modal_title: string;
    locker_modal_button_accept: string;
    openInNewTab_ip_error: string;
  };
  MenuHeaderLogo: {
    menu_header_logo_slogan: string;
  };
  ProductItem: {
    computed_button_title_0: string;
    computed_button_title_1: string;
  };
  GiftcardListView: {
    copiedNotification: string;
    bottom_chart_title: string;
    bottom_chart_button_title: string;
    giftcard_confirm_message: string;
    giftcard_confirm_button_cancel: string;
    giftcard_confirm_button_ok: string;
    giftcard_confirm_message_compatible_with: string;
    anim_generating_code_title: string;
    anim_generating_code_subtitle: string;
    giftcardLockedContainer_title: string;
    generateNewGiftcardSmallLink: string;
    giftcard_confirm_message_how_to_unlock: string;
    giftcard_confirm_button_ok_how_to_unlock: string;
    giftcard_confirm_how_to_unlock_why_small_link: string;
    giftcard_confirm_message_how_to_unlock_why: string;
    giftcard_confirm_button_ok_how_to_unlock_why: string;
    menu_bar_items: {
      category: {
        all: string;
        skins: string;
        codpoints: string;
        battlepass: string;
        weapons: string;
        misc: string;
      };
    };
    goToTasks: {
      alert_checking: string;
      alert_invalid: string;
    };
    showWhyDoINeed_tasks: {
      fragmentLockedRedeemCode_title: string;
    };
  };
}

export let translation: Translation;

export function setTranslation(t:Translation) {
	translation = t;
}

export function getTranslation() {
	return translation;
}

export async function loadDefaultTranslation() {
	translation = require(`@/TranslationHelper/Translations/en.json`) as Translation;
}

async function loadTranslation(url: string): Promise<Translation> {
  try {
    const response = await axios.get(url);
    return response.data as Translation;
  } catch (error) {
    throw new Error(`Failed to load JSON from ${url}: ${error}`);
  }
}

export function getBrowserLanguage(): string {
  return navigator.language;
}

export async function loadAppropriateTranslation() {
  const language = getBrowserLanguage();
  let url: string;
	// const domain = `https://codm.codes/lang`;
	const domain = `@/TranslationHelper/Translations`;

	let isEnglish = false;

  if (language.startsWith('en')) {
    url = `${domain}/en.json`;
		isEnglish = true;
  } else if (language.startsWith('de')) {
    url = `${domain}/de.json`;
  } else if (language.startsWith('ja')) {
    url = `${domain}/ja.json`;
  } else if (language.startsWith('fr')) {
    url = `${domain}/fr.json`;
  } else if (language.startsWith('es')) {
    url = `${domain}/es.json`;
  } else if (language.startsWith('pt')) {
    url = `${domain}/pt_br.json`;
  } else if (language.startsWith('it')) {
    url = `${domain}/it.json`;
  } else if (language.startsWith('fil')) {
    url = `${domain}/fil.json`;
  } else if (language.startsWith('hi')) {
    url = `${domain}/hi.json`;
  } else if (language.startsWith('zh-TW')) {
    url = `${domain}/zh_tw.json`;
  } else if (language.startsWith('zu')) {
    url = `${domain}/zu.json`;
  } else if (language.startsWith('xh')) {
    url = `${domain}/xh.json`;
  } else if (language.startsWith('af')) {
    url = `${domain}/af.json`;
  } else {
    // Default to English if the language is not supported
    url = `${domain}/en.json`;
		isEnglish = true;
  }

  // translation = isEnglish ? require(`@/TranslationHelper/Translations/en.json`) as Translation : await loadTranslation(url);
  translation = require(url) as Translation;
}

// export function getTranslation(): Translation {
// 	return translation;
// }

// loadTranslation(`${domain}/translation.json`)
//   .then((data) => {
//     console.log('Translation loaded:', data);
//   })
//   .catch((error) => {
//     console.error('Error loading translation:', error);
//   });
