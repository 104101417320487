
	import { emitter } from '@/emitter';
	import { logt, sleep } from '@/LogUtils';
	import * as InterfaceHelper from '@/Interfaces';
	import { defineComponent } from 'vue';
	import { useRoute } from 'vue-router';
	import axios from 'axios';
	import LockerModal from '@/components/LockerModal.vue';

	import { locker } from '@/global';

	import * as TranslationHelper from '@/TranslationHelper/TranslationHelper';



	export default defineComponent({
		name: 'App',
		components: {
			LockerModal,
		},

		data() {
			return {

				TAG:`App`,

				translation: {} as TranslationHelper.Translation,


				ggl_credential: null as null|InterfaceHelper.ggl_credential,
				show_giveaway_balloon:true,


				referral_accesses:0 as number,
				stopCheckRefAccesses:false,



				invalid_conn_sent:false,
				invalid_conn:'checking' as string|boolean,
				invalid_conn_data: null as any,
				geo_from_checkconn:null as string|null|undefined,



				router: undefined as any,




				
				locker_modal_visible:false,
				geo: ``,
				domain:``,
				aff_sub4:'',
				aff_sub5:null as null|string,
				selected_item: {
						"key": -1,
						"title": "null",
						"description": "null",
						"icon": "null",
						"link": "null"
				} as InterfaceHelper.item_obj,
			}
		},

		beforeMount() {
			// TranslationHelper.loadAppropriateTranslation();
			// this.translation = TranslationHelper.getTranslation();
			this.loadAppropriateTranslation();
		},

		mounted() {
			// setTimeout(() => {
			// 	let copiedNotification = this.$refs.copiedNotification as HTMLElement;
			// 	copiedNotification.innerHTML = `${this.translation.GiftcardListView.copiedNotification}`;
			// }, 3000);

			this.router = useRoute();

			// setTimeout(async () => {
			// 	await this.sendPageVisit();
			// }, 500);

			
			setTimeout(async () => {
				/* @ts-ignore */
				await this.sendPageVisit();
				if(!this.stopCheckRefAccesses) {
					await sleep(1);
					const route_name = this.router.name;
					if(String(route_name) === 'unlock') {
						this.referral_accesses = await this.check_referral_accesses();
					}
				}
			}, 500);

			emitter.on(`check-referral-accesses:stop`,async () => {
				this.stopCheckRefAccesses = true;
			});

			emitter.on(`check-referral-accesses:start`,async () => {
				this.stopCheckRefAccesses = false;
			});

			/* @ts-ignore */
			emitter.on(`locker-modal:open`,(data) => {
				
				this.invalid_conn = data.invalid_conn;
				this.domain = data.domain;
				this.aff_sub4 = data.aff_sub4;
				this.aff_sub5 = data.aff_sub5;
				this.geo = data.geo;
				this.selected_item = data.selected_item;

				emitter.emit(`drawer-menu:open`, null);

				this.locker_modal_visible = true;
			});

			emitter.on(`locker-modal:close`,(data:null) => {
				emitter.emit(`drawer-menu:close`, null);
				this.locker_modal_visible = true;
			});

			emitter.on(`notification:copied:show`,(data:null) => {
				const copiedNotification = this.$refs.copiedNotification as HTMLElement;
				copiedNotification.style.display="flex";
				setTimeout(() => {
					copiedNotification.style.display="none";
				},2500);
			});
		},

		watch: {
			invalid_conn(newVal, oldVal) {
				emitter.emit(`set-conn-values`, { invalid_conn: newVal, });
			},
			invalid_conn_sent(newVal, oldVal) {
				emitter.emit(`set-conn-values`, { invalid_conn_sent: newVal, });
			},
			invalid_conn_data(newVal, oldVal) {
				emitter.emit(`set-conn-values`, { invalid_conn_data: newVal, });
				emitter.emit(`locker:load-items`, null);
			},
			geo_code(newVal, oldVal) {
				emitter.emit(`set-conn-values`, { geo_code: newVal, });
			},
			geo_from_checkconn(newVal, oldVal) {
				emitter.emit(`set-conn-values`, { geo_from_checkconn: newVal, });
			},
		},

		methods: {


			loadAppropriateTranslation() {
				const language = TranslationHelper.getBrowserLanguage();
				let url: string;
				// const domain = `https://codm.codes/lang`;
				const domain = `@/TranslationHelper/Translations`;

				let isEnglish = false;

				if (language.startsWith('en')) {
					url = `${domain}/en.json`;
					isEnglish = true;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/en.json`) as TranslationHelper.Translation);
				} else if (language.startsWith('de')) {
					url = `${domain}/de.json`;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/de.json`) as TranslationHelper.Translation);
				} else if (language.startsWith('ja')) {
					url = `${domain}/ja.json`;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/ja.json`) as TranslationHelper.Translation);
				} else if (language.startsWith('fr')) {
					url = `${domain}/fr.json`;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/fr.json`) as TranslationHelper.Translation);
				} else if (language.startsWith('es')) {
					url = `${domain}/es.json`;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/es.json`) as TranslationHelper.Translation);
				} else if (language.startsWith('pt')) {
					url = `${domain}/pt_br.json`;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/pt_br.json`) as TranslationHelper.Translation);
				} else if (language.startsWith('it')) {
					url = `${domain}/it.json`;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/it.json`) as TranslationHelper.Translation);
				} else if (language.startsWith('fil')) {
					url = `${domain}/fil.json`;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/fil.json`) as TranslationHelper.Translation);
				} else if (language.startsWith('hi')) {
					url = `${domain}/hi.json`;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/hi.json`) as TranslationHelper.Translation);
				} else if (language.startsWith('zh-TW')) {
					url = `${domain}/zh_tw.json`;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/zh_tw.json`) as TranslationHelper.Translation);
				} else if (language.startsWith('zu')) {
					url = `${domain}/zu.json`;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/zu.json`) as TranslationHelper.Translation);
				} else if (language.startsWith('xh')) {
					url = `${domain}/xh.json`;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/xh.json`) as TranslationHelper.Translation);
				} else if (language.startsWith('af')) {
					url = `${domain}/af.json`;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/af.json`) as TranslationHelper.Translation);
				} else {
					// Default to English if the language is not supported
					url = `${domain}/en.json`;
					isEnglish = true;
					TranslationHelper.setTranslation(require(`@/TranslationHelper/Translations/en.json`) as TranslationHelper.Translation);
				}

				// translation = isEnglish ? require(`@/TranslationHelper/Translations/en.json`) as Translation : await loadTranslation(url);
				// TranslationHelper.setTranslation(require(url) as TranslationHelper.Translation);
				this.translation = TranslationHelper.getTranslation();
			},

			
			isUnlocked() {
				return (localStorage.getItem(`da51f3a5bb1be78b3d71178e4bc19e9c`) === `b326b5062b2f0e69046810717534cb09`);
			},


			openLockerModal() {
				emitter.emit(`drawer-menu:open`, null);
				this.locker_modal_visible = true;
			},

			closeLockerModal() {
				emitter.emit(`drawer-menu:close`, null);
				this.locker_modal_visible = false;
			},

			async check_referral_accesses(isRetry?:boolean):Promise<number> {
				let tag = `${this.TAG}: check_referral_accesses()`;

				if(isRetry) {
					await sleep(5);
				}

				// // logt(tag,`Checking...`);

				// const conn = localStorage.getItem(`conn`);
				const conn = btoa(await this.get_ip() as string);
				let referral_code = localStorage.getItem(`referral_code`);

				if(
					typeof referral_code !== 'undefined' && 
					referral_code !== null &&
					String(referral_code) !== 'undefined' &&
					String(referral_code) !== 'null' &&
					String(referral_code).trim().replaceAll(` `,``) !== ''
				) {
					//
				} else {

					const currentDateCode = () => {
						const date = new Date();
						let year = String(date.getFullYear()).slice(-2);
						let month = date.getMonth() + 1;
						let day = date.getDate();
						let hour = date.getHours();
						let minute = date.getMinutes();
						let second = date.getSeconds();

						const secondMap = {
							10: 'A',
							20: 'B',
							30: 'C',
							40: 'D',
							50: 'E',
							11: 'F',
							22: 'G',
							33: 'H',
							44: 'J',
							55: 'K',
							15: 'L',
							25: 'M',
							35: 'N',
							45: 'P',
							19: 'Q',
							29: 'R',
							39: 'S',
							49: 'T',
							59: 'U',
							13: 'V',
							23: 'W',
							43: 'X',
							53: 'Y',
							31: 'Z',
						};

						const minuteMap = {
							10: 'A',
							20: 'B',
							30: 'C',
							40: 'D',
							50: 'E',
							11: 'F',
							22: 'G',
							33: 'H',
							44: 'J',
							55: 'K',
							15: 'L',
							25: 'M',
							35: 'N',
							45: 'P',
							19: 'Q',
							29: 'R',
							39: 'S',
							49: 'T',
							59: 'U',
							13: 'V',
							23: 'W',
							43: 'X',
							53: 'Y',
							31: 'Z',
						};

						const hourMap = {
							10: 'A',
							11: 'B',
							12: 'C',
							13: 'D',
							14: 'E',
							15: 'F',
							16: 'G',
							17: 'H',
							18: 'J',
							19: 'K',
							20: 'L',
							21: 'M',
							22: 'N',
							23: 'P',
						};

						const dayMap = {
							10: 'A',
							11: 'B',
							12: 'C',
							13: 'D',
							14: 'E',
							15: 'F',
							16: 'G',
							17: 'H',
							18: 'J',
							19: 'K',
							20: 'L',
							21: 'M',
							22: 'N',
							23: 'P',
							24: 'Q',
							25: 'R',
							26: 'S',
							// Add more mappings as necessary...
						};

						// Get the code for the second, minute, hour and day
						if (second in secondMap) {
							/* @ts-ignore */
							second = secondMap[second];
						}
						if (minute in minuteMap) {
							/* @ts-ignore */
							minute = minuteMap[minute];
						}
						if (hour in hourMap) {
							/* @ts-ignore */
							hour = hourMap[hour];
						}
						if (day in dayMap) {
							/* @ts-ignore */
							day = dayMap[day];
						}

						// Return the concatenated code
						return `${year}${month}${day}${hour}${minute}${second}`;

					};



					const generateRandomString = (length:number, only?:{letters?:boolean, numbers?:boolean, all?:boolean}):string => {
						let result = '';
						let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

						if(typeof only !== 'undefined') {
							if(only.letters) {
								characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
							}
							if(only.numbers) {
								characters = '0123456789';
							}

							
							if(only.all) {
								characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
							}
						}

						const charactersLength = characters.length;

						for (let i = 0; i < length; i++) {
							result += characters.charAt(Math.floor(Math.random() * charactersLength));
						}

						return result;
					}

					// referral_code = `${generateRandomString(1,{letters:true})}${generateRandomString(2,{all:true})}${generateRandomString(3,{numbers:true})}`;
					/* @ts-ignore */
					referral_code = `${generateRandomString(1,{letters:true})}${currentDateCode()}${generateRandomString(1,{numbers:true})}`.toLowerCase();
					localStorage.setItem(`referral_code`,String(referral_code));
				}

				emitter.emit(`change-referral-code`,null);

				const giveaway_page_first_load = localStorage.getItem(`giveaway_page_first_load`);

				
				if(this.router !== 'undefined') {
					const route_name = this.router.name;
					// logt(tag,`route_name:`,route_name)
					if(referral_code === null || String(giveaway_page_first_load) !== 'true') {
						if(String(route_name) !== 'unlock') {
							await sleep(15);
							// return 0;
							// emitter.emit(`check-referral-accesses:stop`);
							// await sleep(1);
							// return 0;
						}
						return await this.check_referral_accesses(true);
					}
				} else {
					return await this.check_referral_accesses(true);
				}

				if(this.stopCheckRefAccesses) {
					return 0;
				}

				// // logt(tag,`conn:`,conn);
				// // logt(tag,`referral_code:`,referral_code);

				/* @ts-ignore */
				let response/*:string|number|null|undefined*/ = await axios.get(`https://codm.codes/api/check_referral_accesses.php?ref=${referral_code}&c=${conn}`);

				let accesses = response.data.accesses;

				if(typeof accesses !== 'undefined' && accesses !== null) {
					// // logt(tag,`accesses:`,accesses);
					accesses = Number(accesses);
					// // logt(tag,`accesses:`,accesses);
					return (!isNaN(accesses) && accesses > 0) ? accesses : await this.check_referral_accesses(true);
				}

				return await this.check_referral_accesses(true);

			},

			// async check_referral_accesses(isRetry?:boolean):Promise<number> {
			// 	let tag = `${this.TAG}: check_referral_accesses()`;

			// 	logt(tag,`Checking...`);

			// 	if(isRetry) {
			// 		await sleep(5);
			// 	} else {
			// 		await sleep(1);
			// 	}

			// 	// // logt(tag,`Checking...`);

			// 	// const conn = localStorage.getItem(`conn`);
			// 	const conn = btoa(await this.get_ip() as string);
			// 	let referral_code = localStorage.getItem(`referral_code`);

			// 	if(
			// 		typeof referral_code !== 'undefined' && 
			// 		referral_code !== null &&
			// 		String(referral_code) !== 'undefined' &&
			// 		String(referral_code) !== 'null' &&
			// 		String(referral_code).trim().replaceAll(` `,``) !== ''
			// 	) {
			// 		//
			// 	} else {

			// 		const currentDateCode = () => {
			// 			const date = new Date();
			// 			let year = String(date.getFullYear()).slice(-2);
			// 			let month = date.getMonth() + 1;
			// 			let day = date.getDate();
			// 			let hour = date.getHours();
			// 			let minute = date.getMinutes();
			// 			let second = date.getSeconds();

			// 			const secondMap = {
			// 				10: 'A',
			// 				20: 'B',
			// 				30: 'C',
			// 				40: 'D',
			// 				50: 'E',
			// 				11: 'F',
			// 				22: 'G',
			// 				33: 'H',
			// 				44: 'J',
			// 				55: 'K',
			// 				15: 'L',
			// 				25: 'M',
			// 				35: 'N',
			// 				45: 'P',
			// 				19: 'Q',
			// 				29: 'R',
			// 				39: 'S',
			// 				49: 'T',
			// 				59: 'U',
			// 				13: 'V',
			// 				23: 'W',
			// 				43: 'X',
			// 				53: 'Y',
			// 				31: 'Z',
			// 			};

			// 			const minuteMap = {
			// 				10: 'A',
			// 				20: 'B',
			// 				30: 'C',
			// 				40: 'D',
			// 				50: 'E',
			// 				11: 'F',
			// 				22: 'G',
			// 				33: 'H',
			// 				44: 'J',
			// 				55: 'K',
			// 				15: 'L',
			// 				25: 'M',
			// 				35: 'N',
			// 				45: 'P',
			// 				19: 'Q',
			// 				29: 'R',
			// 				39: 'S',
			// 				49: 'T',
			// 				59: 'U',
			// 				13: 'V',
			// 				23: 'W',
			// 				43: 'X',
			// 				53: 'Y',
			// 				31: 'Z',
			// 			};

			// 			const hourMap = {
			// 				10: 'A',
			// 				11: 'B',
			// 				12: 'C',
			// 				13: 'D',
			// 				14: 'E',
			// 				15: 'F',
			// 				16: 'G',
			// 				17: 'H',
			// 				18: 'J',
			// 				19: 'K',
			// 				20: 'L',
			// 				21: 'M',
			// 				22: 'N',
			// 				23: 'P',
			// 			};

			// 			const dayMap = {
			// 				10: 'A',
			// 				11: 'B',
			// 				12: 'C',
			// 				13: 'D',
			// 				14: 'E',
			// 				15: 'F',
			// 				16: 'G',
			// 				17: 'H',
			// 				18: 'J',
			// 				19: 'K',
			// 				20: 'L',
			// 				21: 'M',
			// 				22: 'N',
			// 				23: 'P',
			// 				24: 'Q',
			// 				25: 'R',
			// 				26: 'S',
			// 				// Add more mappings as necessary...
			// 			};

			// 			// Get the code for the second, minute, hour and day
			// 			if (second in secondMap) {
			// 				/* @ts-ignore */
			// 				second = secondMap[second];
			// 			}
			// 			if (minute in minuteMap) {
			// 				/* @ts-ignore */
			// 				minute = minuteMap[minute];
			// 			}
			// 			if (hour in hourMap) {
			// 				/* @ts-ignore */
			// 				hour = hourMap[hour];
			// 			}
			// 			if (day in dayMap) {
			// 				/* @ts-ignore */
			// 				day = dayMap[day];
			// 			}

			// 			// Return the concatenated code
			// 			return `${year}${month}${day}${hour}${minute}${second}`;

			// 		};



			// 		const generateRandomString = (length:number, only?:{letters?:boolean, numbers?:boolean, all?:boolean}):string => {
			// 			let result = '';
			// 			let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

			// 			if(typeof only !== 'undefined') {
			// 				if(only.letters) {
			// 					characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
			// 				}
			// 				if(only.numbers) {
			// 					characters = '0123456789';
			// 				}

							
			// 				if(only.all) {
			// 					characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			// 				}
			// 			}

			// 			const charactersLength = characters.length;

			// 			for (let i = 0; i < length; i++) {
			// 				result += characters.charAt(Math.floor(Math.random() * charactersLength));
			// 			}

			// 			return result;
			// 		}

			// 		// referral_code = `${generateRandomString(1,{letters:true})}${generateRandomString(2,{all:true})}${generateRandomString(3,{numbers:true})}`;
			// 		/* @ts-ignore */
			// 		referral_code = `${generateRandomString(1,{letters:true})}${currentDateCode()}${generateRandomString(1,{numbers:true})}`.toLowerCase();
			// 		localStorage.setItem(`referral_code`,String(referral_code));
			// 	}

			// 	emitter.emit(`change-referral-code`,null);

			// 	const giveaway_page_first_load = localStorage.getItem(`giveaway_page_first_load`);

			// 	let route_name = null;
				
			// 	if(this.router !== 'undefined') {
			// 		route_name = this.router.name;
			// 		// logt(tag,`route_name:`,route_name)
			// 		if(referral_code === null || String(giveaway_page_first_load) !== 'true' || String(route_name) === 'unlock') {
			// 			return await this.check_referral_accesses(true);
			// 		}
			// 	} else {
			// 		return await this.check_referral_accesses(true);
			// 		// return 0;
			// 	}

			// 	if(this.stopCheckRefAccesses) {
			// 		return 0;
			// 	}

			// 	// // logt(tag,`conn:`,conn);
			// 	// // logt(tag,`referral_code:`,referral_code);

			// 	/* @ts-ignore */
			// 	let response/*:string|number|null|undefined*/ = await axios.get(`https://codm.codes/api/check_referral_accesses.php?ref=${referral_code}&c=${conn}`);

			// 	let accesses = response.data.accesses;

			// 	if(typeof accesses !== 'undefined' && accesses !== null) {
			// 		// // logt(tag,`accesses:`,accesses);
			// 		accesses = Number(accesses);
			// 		// // logt(tag,`accesses:`,accesses);
			// 		if(/*referral_code === null || String(giveaway_page_first_load) !== 'true' ||*/ String(route_name) === 'unlock') {
			// 			return (!isNaN(accesses) && accesses > 0) ? accesses : await this.check_referral_accesses(true);
			// 		} else {
			// 			return 0;
			// 		}
			// 	}

			// 	return await this.check_referral_accesses(true);

			// },








			/* eslint-disable no-unused-vars */
			/* eslint-disable no-async-promise-executor */
			get_ip() {
				return new Promise((resolve, reject) => {
					/* @ts-ignore */
					axios.get(`https://codm.codes/api/get_ip.php`)
					/* @ts-ignore */
					.then((response) => {
						const ip = response.data.ip;
						localStorage.setItem(`conn`,btoa(ip))
						resolve(ip);
					})
					/* @ts-ignore */
					.catch((err) => reject(undefined));
				});
			},
			/* eslint-enable no-async-promise-executor */
			/* eslint-enable no-unused-vars */


			/* eslint-disable no-unused-vars */
			/* eslint-disable no-async-promise-executor */
			/* @ts-ignore */
			async register_invalid_conn(data) {
				// console.log(data);
				return new Promise((resolve, reject) => {
					/* @ts-ignore */
					if(!this.invalid_conn_sent) {
						/* @ts-ignore */
						const api = axios.create({baseURL: `https://codm.codes/api`})
						api.postForm(`/register_invalid_conn_2.php`, {
							ip:data.ip,
							country_code:data.location.country_code,
							is_datacenter:data.is_datacenter,
							is_tor:data.is_tor,
							is_proxy:data.is_proxy,
							is_abuser:data.is_abuser,
							is_bogon:data.is_bogon,
							is_vpn:data.is_vpn,
						}).then(() => {
							/* @ts-ignore */
							this.invalid_conn_sent = true;
							resolve(true);
						});
					} else {
						resolve(true);
					}
				});
			},
			/* eslint-enable no-async-promise-executor */
			/* eslint-enable no-unused-vars */


			/* eslint-disable no-unused-vars */
			/* eslint-disable no-async-promise-executor */
			async get_geo() {
				return new Promise(async (resolve, reject) => {

					
					/* @ts-ignore */
					const ip = await this.get_ip();


					if(typeof ip !== "undefined") {
						/* @ts-ignore */
						// const api = axios.create({baseURL: `https://api.incolumitas.com`})
						const api = axios.create({baseURL: `https://api.ipapi.is`})
						api.defaults.timeout = 10000;
						/* @ts-ignore */
						api.get(`/?q=${ip}`).then(async (response) => {
							const data = response.data;
							
							let geo = String(data.location.country_code).toUpperCase();
							localStorage.setItem(`geo`, geo);

							let is_invalid = !(
								data.is_datacenter || 
								data.is_bogon || 
								data.is_vpn || 
								data.is_tor || 
								data.is_proxy || 
								data.is_abuser
							);


							localStorage.setItem(`YzJWdmMzWXRhWEE9`, btoa(btoa(String(ip).toLowerCase().trim())));
							localStorage.setItem(`YzJWdmMzWXRaMlZ2`, btoa(btoa(String(geo).toLowerCase().trim())));
							localStorage.setItem(`YzJWdmMzWXRhWE53`, btoa(btoa(String(data.asn.org)+';'+String(data.asn.domain))));
							localStorage.setItem(`YzJWdmMzWXRkSGx3WlE9PQ==`, btoa(btoa(String(data.asn.type))));
							// localStorage.setItem(`YzJWdmMzWXRkbUZzYVdSZmFYTnc=`, btoa(btoa(String(String(data.company.type).toLowerCase().trim() === atob(atob('YVhOdw=='))).toLowerCase().trim())));
							localStorage.setItem(`YzJWdmMzWXRkbUZzYVdSZmFYTnc=`, btoa(btoa(String(String(is_invalid) == atob(atob('ZEhKMVpRPT0='))))));

							emitter.emit(atob(atob('YzJWdmMzWXRjMlZ1WkE9PQ==')));


							if(
								(data.is_datacenter && data.is_bogon) || 
								(data.is_datacenter && data.is_vpn) || 
								(data.is_datacenter && data.is_tor) || 
								(data.is_datacenter && data.is_proxy) || 
								(data.is_datacenter && data.is_abuser) ||
								data.is_datacenter || 
								data.is_bogon || 
								data.is_vpn || 
								data.is_tor || 
								data.is_proxy || 
								data.is_abuser
							) {
								/* @ts-ignore */
								this.invalid_conn = true;
								/* @ts-ignore */
								await this.register_invalid_conn(data);
							} else {
								/* @ts-ignore */
								this.invalid_conn = false;
							}

							// TODO: test only
							// this.invalid_conn = 'checking';
							// await this.register_invalid_conn(data);

							
							/* @ts-ignore */
							this.invalid_conn_data = data;


							resolve(geo);
						/* @ts-ignore */
						}).catch((err) => {
							/* @ts-ignore */
							this.invalid_conn = true;
							resolve('null');
						});
					}
				});
			},
			/* eslint-enable no-async-promise-executor */
			/* eslint-enable no-unused-vars */
			
			async sendPageVisit() {


				/* @ts-ignore */
				this.geo_from_checkconn = await this.get_geo();
				/* @ts-ignore */
				this.geo_from_checkconn = (typeof this.geo_from_checkconn === 'undefined' || String(this.geo_from_checkconn) === 'null') ? await this.get_geo() : this.geo_from_checkconn;
				/* @ts-ignore */
				this.geo_from_checkconn = (typeof this.geo_from_checkconn === 'undefined' || String(this.geo_from_checkconn) === 'null') ? `null` : String(this.geo_from_checkconn);

				
				let param_geo = "";


				/* @ts-ignore */
				let geo = this.geo_from_checkconn;


				localStorage.setItem("geo",String(geo));
				if(typeof geo === 'string' || geo !== null) { param_geo = "geo="+String(geo); }


				let param_r = new URL(location.href).searchParams.get('r');
				let param_r_url = param_geo+"&";
				if(param_r != null) { param_r_url += "r="+param_r+"&"; }


				await fetch("https://codm.codes/api/send_page_visit_2.php?"+param_r_url+"page="+btoa(window.location.href));
				await fetch("https://codm.codes/api/send_page_visit.php?"+param_r_url+"page="+btoa(window.location.href));

				
				const response_target = await fetch("https://codm.codes/api/get_target.php");
				const data_target = await response_target.json();
				let next_target = -1;
				next_target = data_target.next_target;
				localStorage.setItem("next_target",String(next_target));

			},
		}
	})
